window.updateShowPreviewPlitka = () => {
    let previews;
    if ((previews = document.querySelectorAll(".catalogCollectionCard .preview_products")) && previews.length) {
        let first = previews[0];
        let styles = getComputedStyle(first.querySelector("a"));
        let margin_block = parseInt(styles["margin-right"]);
        let sizes = first.getBoundingClientRect();
        let width_block = sizes.width;
        let showmore_block = sizes.height;
        let max_width = width_block - showmore_block - margin_block*2;
        previews.forEach(function (block) {
            let width_cur = 0;
            block.querySelectorAll("a:not(.showmore_col) > img").forEach(function (img, i) {
                width_cur += parseInt(img.getAttribute("width"));
                width_cur += margin_block*2;
            })
            let showmore_col = block.querySelector("a.showmore_col");
            if (width_cur > width_block) {
                width_cur = 0;
                let count_show = 0;
                block.querySelectorAll("a:not(.showmore_col) > img").forEach(function (img, i) {
                    let a = img.parentElement;
                    width_cur += parseInt(img.getAttribute("width"));
                    width_cur += margin_block*2;

                    if (width_cur > max_width) {
                        if (i > 0) {
                            a.style.display = 'none';
                        }
                    } else {
                        a.style.display = 'block';
                        count_show++;
                    }
                });
                if (!showmore_col) {
                    showmore_col = document.createElement("a");
                    showmore_col.classList.add("showmore_col");
                    showmore_col.classList.add("catalogCollectionCard__imgsCard");
                    showmore_col.classList.add("catalogCollectionCard__imgsCard--text");
                    showmore_col.href = block.closest(".catalogCollectionCard").querySelector("a.catalogCollectionCard__name2").href;
                    block.appendChild(showmore_col);
                }
                block.querySelector("a.showmore_col").innerText = "+" + (block.dataset["count"] - Math.max(1, count_show));
            } else {
                block.querySelectorAll("a:not(.showmore_col)").forEach(function (a, i) {
                    a.style.display = 'block';
                })
                showmore_col && showmore_col.remove();
            }
        })
    }
}

window.addEventListener("resize", updateShowPreviewPlitka);
//window.dispatchEvent(new Event('resize'));
updateShowPreviewPlitka();
