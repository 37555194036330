class GlobaSite {
    initMobileMenu() {
        let menu_items
        if ((menu_items = document.querySelectorAll(".headerKatalog__content[data-menu-block='main'] .catalogList__category")) && menu_items.length)
        {
            menu_items.forEach(function (item) {
                let a = item.querySelector(".headerKatalog__contentNameCat");
                let img = item.querySelector(".catalogList__img img");
                let href = a.href;
                let item_id = a.dataset["menuItem"];
                let title = a.innerText.trim();
                let img_src = img.dataset["imageMob"];

                let el;
                if(item_id) {
                    el = document.createElement("button");
                    el.dataset["mainmenuItem"] = item_id;
                    el.dataset["graphPath"] = "categor";
                }
                else {
                    el = document.createElement("a");
                    el.href = href;
                }
                let img_el = document.createElement("img");
                img_el.classList.add("modalMain__menuListItemImg");
                img_el.classList.add("lazyload");
                img_el.dataset["src"] = img_src;
                img_el.width = 60;
                img_el.height = 60;

                let title_el = document.createElement("div");
                title_el.classList.add("modalMain__menuListItemText");
                title_el.append(title);

                el.classList.add("modalMain__menuListItem");
                el.classList.add("btn-reset");
                el.classList.add("modal-btn");
                el.append(img_el);
                el.append(title_el);

                document.getElementById("menu_main_mobile_list").append(el);
            });
            if(typeof window.initModalBtn == "function")
                window.initModalBtn();

        }
    }

    initMobileMenuModal(id){
        let obj = document.getElementById("menu_children_mobile_list");
        let items = document.querySelectorAll("[data-menu-block='" + id + "'] .catalogList__category");

        obj.innerHTML = "";
        if(items.length) {
            items.forEach(function(item){
                let item_a = item.querySelector(".headerKatalog__contentNameCat");
                let item_img = item.querySelector(".catalogList__img img");

                let name = item_a.innerText.trim();
                let a = document.createElement("a");
                a.href = item_a.href;
                a.classList.add("modalCategor__categor");

                if(item_img.dataset["imageMob"]) {
                    let img = document.createElement("img");
                    img.src = item_img.dataset["imageMob"];
                    img.alt = name;
                    img.classList.add("modalCategor__img");
                    a.append(img);
                }
                let span = document.createElement("div");
                span.classList.add("modalCategor__name");
                span.append(name);

                a.append(span);
                obj.append(a);
            });
        }
    }
}
window.App = new GlobaSite;
